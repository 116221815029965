import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { ProtectedRoute } from "contexts/auth";
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/home`}
            component={lazy(() => import(`./home`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/risks`}
            component={lazy(() => import(`./risks`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/failures`}
            component={lazy(() => import(`./failures`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/high-speed`}
            component={lazy(() => import(`./high-speed`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/roi`}
            component={lazy(() => import(`./roi`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/fuel`}
            component={lazy(() => import(`./fuel`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/fuel_v2`}
            component={lazy(() => import(`./fuel_v2`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/idle`}
            component={lazy(() => import(`./idle`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/traveled`}
            component={lazy(() => import(`./traveled`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/devices`}
            component={lazy(() => import(`./devices`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/report`}
            component={lazy(() => import(`./report_v2`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/trends`}
            component={lazy(() => import(`./trends`))}
          />
          <ProtectedRoute
            path={`${APP_PREFIX_PATH}/temp_hum`}
            component={lazy(() => import(`./reportAditional`))}
          />
           <ProtectedRoute
            path={`${APP_PREFIX_PATH}/analysis`}
            component={lazy(() => import(`./analysis`))}
          />
           <ProtectedRoute
            path={`${APP_PREFIX_PATH}/last_mile`}
            component={lazy(() => import(`./last_mile`))}
          />
           <ProtectedRoute
            path={`${APP_PREFIX_PATH}/co2`}
            component={lazy(() => import(`./co2`))}
          />
           <ProtectedRoute
            path={`${APP_PREFIX_PATH}/tolls`}
            component={lazy(() => import(`./tolls`))}
          />
           <ProtectedRoute
            path={`${APP_PREFIX_PATH}/disconnection`}
            component={lazy(() => import(`./disconnection`))}
          />
           {/* <ProtectedRoute
            path={`${APP_PREFIX_PATH}/report_v2`}
            component={lazy(() => import(`./report_v2`))}
          /> */}
      
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);